<template>
  <v-container
    style="justify-content: center; height: 100%; background: #FFFFFF 0% 0% no-repeat padding-box; opacity: 1;">

    <div class="text-center">
      <br>
      <b :class="gracias"> ¡MUCHAS GRACIAS POR COMPRAR TU SOAP CON NOSOTROS!</b>

      <br><br>

      <b v-if="success == 'true'" class="correo">Tu póliza fue enviada a tu correo electrónico y a tu teléfono por Whatsapp</b>

      <b v-if="success == 'false'" class="correo">Tu póliza será enviada en las próximas horas a tu correo y por WhatsApp</b>

      <br><br>

      <template v-if="!loadingData">
        <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="patentes"
          :items-per-page="isMobile ? 1 : 5" sort-by="price" class="elevation-1 table" no-data-text="NO HAY REGISTROS ¡AÑADE TU SOAP!">


          <template v-slot:item.actions="{ item }">
            <v-btn v-if="success == 'true'" class="btnDownload" id="descarga_5" @click="DescargarArchivo(item)">Descargar</v-btn>
            <v-btn v-if="success == 'false'" disabled class="btnDownload" @click="DescargarArchivo(item)">Descargar</v-btn>
          </template>
          <template v-slot:item.price="{ item }">
            <label style="margin:auto">{{ formatPrice(item.price) }}</label>
          </template>
        </v-data-table>
      </template>

      <div v-if="loadingData" class="text-center">
        <br>
        <br>
        <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
        Cargando
      </div>

    </div>

  </v-container>
</template>

<script>
import webPayLogo from "@/assets/images/webpay.png";
import ShieldService from "../services/shield.service";
import swal from 'sweetalert';
import Vue from "vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {
  name: "PlanForm",
  data: () => ({
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    success: null,
    isMobile: false,
    screen: window.screen.width,
    descargar: null,
    webPayLogo,
    token: null,
    keygen: null,
    patentes: null,
    gracias: null,
    loadingData: true,
    headers: [
      {
        text: 'Patente',
        align: 'start',
        sortable: false,
        value: 'patenteNumber',
        align: 'center',
      },
      { text: 'Tipo de Vehículo', value: 'typeVehicle', align: 'center', sortable: false, },
      { text: 'Modelo', value: 'modelVehicle', align: 'center', sortable: false, },
      { text: 'Correo', value: 'personEmail', align: 'center', sortable: false, },
      { text: 'Valor SOAP', value: 'price', align: 'center', sortable: false, },
      { text: 'Descargar', value: 'actions', sortable: false, align: 'center', },
    ],
  }),
  async mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
    const query = this.$route.query;
    if (this.screen > 800) {
      this.descargar = 'descargar'
      this.gracias = 'gracias'
    } else if (this.screen <= 800) {
      this.descargar = 'descargar2'
      this.gracias = 'gracias2'
    }
    if (query.reload) {
      swal({
        title: "¡Intente nuevamente!",
        text: "Ahora puedes descargar tu póliza",
        icon: "info",
        button: "Ok",
      })
    }

    setTimeout(() => {
      this.getInfo()
    }, 3000);

  },
  methods: {
    back() {
      this.$emit("back");
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 800;
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    async DescargarArchivo(item) {  
      if (item.pdf) {
        const url = window.URL.createObjectURL(new Blob([new Uint8Array(item.pdf.data).buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `vehiculo-${item.patenteNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
      } else {

        try {

          // reCaptcha v3 token
          await this.$recaptchaLoaded()
          const tokenAmazonia = await this.$recaptcha('getAmazonia');
          let amazoniaRes = await ShieldService.getAmazonia(item.numeroPoliza, tokenAmazonia);

          if (amazoniaRes.status == 'ok') {
            const byteCharacters = atob(amazoniaRes.data.data);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${item.patenteNumber}-${item.personNames}.pdf`);
            document.body.appendChild(link);

            link.click();
          }

        } catch (error) {
          console.log(error)
        }

      }
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async getInfo() {
      const query = this.$route.query;
      if (query) {
        if (query.success == 'true' && query.token) {
          this.token = query.token
          this.success = query.success
          this.patentes = await ShieldService.getToken(this.token)
          this.loadingData = false
        } else if (query.success == 'true' && query.keygen) {
          this.keygen = query.keygen
          this.success = query.success
          this.patentes = await ShieldService.getKeygen(this.keygen)
          this.loadingData = false
        } else if (query.success == 'false' && query.token) {
          this.token = query.token
          this.success = query.success
          this.patentes = await ShieldService.getToken(this.token)
          this.loadingData = false
        } else if (query.success == 'false' && query.keygen) {
          this.keygen = query.keygen
          this.success = query.success
          this.patentes = await ShieldService.getKeygen(this.keygen)
          this.loadingData = false
        }
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.gracias {
  width: 750px;
  height: 29px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: -0.36px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.gracias2 {
  width: 750px;
  height: 29px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 28px;
  letter-spacing: -0.36px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;

}


.correo {
  width: 295px;
  height: 23px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  letter-spacing: -0.24px;
  color: #2D387C;
}

.email {
  width: 230px;
  height: 23px;
  text-align: center;
  font-family: "Scotia_Legal";
  font-size: 20px;
  letter-spacing: 0px;
  color: #D74E80;
  text-transform: uppercase;
}

.preferencia {
  width: 416px;
  height: 17px;
  text-align: center;
  font-family: "Scotia_Legal";
  font-size: 15px;
  letter-spacing: 0px;
  color: #5C5C5C;
  opacity: 1;
}

.webpay {
  width: 482px;
  height: 206px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
}

.descargar {
  margin-left: 37%;
}

.descargar2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30%;
}

.tableHeaders {
  background-color: #2D387C;
  color: white !important;
  border: 2px solid #2D387C;
}

.text-center {
  text-align: center;
}

.btnDownload {
  background-color: #1D9C6F !important;
  color: #FFF;
  border-radius: 6px;
  letter-spacing: 0.8px;
  font-weight: bold;
  text-transform: none;
}

.table {
  border: 2px solid #2D387C;
}
</style>

<style lang="css">
.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  background-color: #2D387C;
  color: white !important;
}
</style>